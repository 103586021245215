import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MediaImageModal from './MediaImageModal';
import MediaVideoModal from './MediaVideoModal';
import axios from 'axios';
import PageLoadingSpinner from '../FullPageLoader';
import VideoProcessing from "../../assets/video-processing.jpg";
/*import './mediacomponent.css';*/
import ReactDragListView from 'react-drag-listview';


function MediaDisplay({ imageId, imageSrc, imageAlt, children }) {
  return (
      <div className="galleryItem node-selector select-handler videoThumbWrap" data-element="galleryItem" data-id={imageId}>
      <img className="galleryImage" src={imageSrc} alt={imageAlt} />
      {children}
    </div>
  )
}
MediaDisplay.propTypes = {
  imageId: PropTypes.number,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  children: PropTypes.node
}

function MediaGallery({ mediaItems, onMediaDelete, allowSorting, sortInTable, saveMediaItems/*, onMediaEdit*/ }) {

  //const handleEditItem = (e) => {
  //  let itemId = e.target.closest('[data-element="galleryItem"]').dataset.id;
  //  onMediaEdit(itemId);
  //}
    const [sortedMediaItems, setSortedMediaItems] = useState({});
  const handleDeleteMedia = (e) => {
    let itemId = e.target.closest('[data-element="galleryItem"]').dataset.id;
    onMediaDelete(itemId);
  }

    useEffect(() => {
        setSortedMediaItems(mediaItems);
    }, [mediaItems]);

  if (mediaItems.length > 0) {
      return (
        <>
              {(allowSorting === true) ?
                  <ReactDragListView
                      nodeSelector='.node-selector'
                      handleSelector='.select-handler'
                      allowDragAndDrop={true}
                      onDragEnd={(fromIndex, toIndex) => {
                          const updatedItems = [...sortedMediaItems];
                          const [draggedItem] = updatedItems.splice(fromIndex, 1);
                          updatedItems.splice(toIndex, 0, draggedItem);
                          setSortedMediaItems(updatedItems);
                          console.log(sortInTable);
                          saveMediaItems(updatedItems);
                      }}>
                      <div className="mediaGallery">
                          {sortedMediaItems.map((item, idx) =>
                              <MediaDisplay
                                  key={'mediaItem_' + idx}
                                  imageId={item.id}
                                  imageSrc={item?.videoCustomThumbnailUrl ? item.videoCustomThumbnailUrl : item?.previewsrc && item?.previewsrc == '/images/video-processing.jpg' ? VideoProcessing : item?.previewsrc}
                                  imageAlt={item.altText}
                              >
                                  <div className="media-thumbnail-action-wrap">
                                      {/* Hiding edit for now until we get logic for it in place. User can always delete and add item */}

                                      {/*<button className="media-thumbnail-action red margin-bt-edit-image"*/}
                                      {/*    onClick={handleEditItem}*/}
                                      {/*>*/}
                                      {/*    <span className="material-symbols-rounded">edit</span>*/}
                                      {/*</button>*/}
                                      <button className="media-thumbnail-action red"
                                          onClick={handleDeleteMedia}
                                      >
                                          <span className="material-symbols-rounded">delete</span>
                                      </button>
                                  </div>
                              </MediaDisplay>
                          )}
                      </div>
                  </ReactDragListView>
                  :
                  <div className="mediaGallery">
                      {mediaItems.map((item, idx) =>
                          <MediaDisplay
                              key={'mediaItem_' + idx}
                              imageId={item.id}
                              imageSrc={item?.videoCustomThumbnailUrl ? item.videoCustomThumbnailUrl : item?.previewsrc && item?.previewsrc == '/images/video-processing.jpg' ? VideoProcessing : item?.previewsrc}
                              imageAlt={item.altText}
                          >
                              <div className="media-thumbnail-action-wrap">
                                  {/* Hiding edit for now until we get logic for it in place. User can always delete and add item */}
                                  {/*<button className="media-thumbnail-action"*/}
                                  {/*  onClick={handleEditItem}*/}
                                  {/*>*/}
                                  {/*  <span className="material-symbols-rounded">edit</span>*/}
                                  {/*</button>*/}
                                  <button className="media-thumbnail-action red"
                                      onClick={handleDeleteMedia}
                                  >
                                      <span className="material-symbols-rounded">delete</span>
                                  </button>
                              </div>
                          </MediaDisplay>
                      )}
                  </div>
              }
      </>
    )
  }
  else {
    return null;
  }
}
MediaGallery.propTypes = {
  mediaItems: PropTypes.array,
  onMediaDelete: PropTypes.func,
  onMediaEdit: PropTypes.func,
  allowSorting: PropTypes.bool,
  sortInTable: PropTypes.string,
  saveMediaItems: PropTypes.func
}

export default function MediaUploader({
  forceCrop, allowGallery, imageOnly, videoOnly, requireAltText,
  mediaItems, saveMediaItems, customImageSave, allowSorting, sortInTable }) {

  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [ogImageSrc, setOgImageSrc] = useState(null);
  const [editImageObj, setEditImageObj] = useState(null);
  const [mediaLibrary, setLibrary] = useState(mediaItems ?? []);
  const [showSaving, setShowSaving] = useState(false);

  const handleSaveMedia = async (mediaObj) => {
      setShowSaving(true);
    axios({
      url: '/api/media/add',
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: mediaObj
    })
      .then((result) => {

        let newMediaItem = {
          id: result.data.data.id,
          altText: result.data.data.altText && result.data.data.altText.trim() !== "" ? result.data.data.altText : result.data.data.photo?.originalFileName,
          previewsrc: result.data.previewsrc,
          mediaitemid: result.data.data.id
        }
        setOgImageSrc(null);
        setShowImageModal(false);
        setShowVideoModal(false);

          if (mediaObj != null && mediaObj.id > 0) {
              // Find the index of the existing media item with the same id
              const entityIndex = mediaLibrary.findIndex(x => x.id === mediaObj.id); 
              if (entityIndex > -1) {
                  // If the existing media item is found, remove it using splice 
                  mediaLibrary.splice(entityIndex, 1); 
                  // Add the new media item at the same index
                  mediaLibrary.splice(entityIndex, 0, newMediaItem); 
                  saveMediaItems([...mediaLibrary]);

              } else {
                  // If the existing media item is not found, simply add the new media item
                  setLibrary([...mediaLibrary, newMediaItem]);
                  saveMediaItems([...mediaLibrary, newMediaItem]);
              }
          } else {
              // Handle the case where mediaObj is null or id is not greater than 0
              // Add the new media item to the library without removing any existing item
              setLibrary([...mediaLibrary, newMediaItem]);
              saveMediaItems([...mediaLibrary, newMediaItem]);
          }
        setShowSaving(false);
      })
      .catch((error) => {
        setShowSaving(false);
        alert(error);
      });
  }


  const handleMediaDelete = (mediaId) => {
    setLibrary(mediaLibrary.filter(x => x.id != mediaId));
    saveMediaItems(mediaLibrary.filter(x => x.id != mediaId));
  }
  const handleMediaEdit = (mediaId) => {
    let mediaObj = mediaLibrary.filter(x => x.id == mediaId)[0];
    setEditImageObj(mediaObj);
    setShowImageModal(true);
  }

  useEffect(() => {
    setLibrary(mediaItems);
  }, [mediaItems]);

  return (
    <>
      <MediaGallery
        mediaItems={mediaLibrary}
        onMediaDelete={handleMediaDelete}
        onMediaEdit={handleMediaEdit}
        allowSorting={allowSorting}
        sortInTable={sortInTable}
        saveMediaItems={saveMediaItems}
      />
      {(!allowGallery && mediaLibrary.length > 0) ? null : (
        <div className="mediaOptions">
          {(imageOnly || !videoOnly) ? (
            <label className="mediaAddImage">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setShowImageModal(true);
                  setOgImageSrc(e.target.files[0])
                }} />
              <span className="mediaAdd-button">Add Image</span>
            </label>
          ) : (<></>)}
          {(videoOnly || !imageOnly) ? (
            <button
              className="mediaAddVideo"
              onClick={() => {
                if (showImageModal) setShowImageModal(false);
                setShowVideoModal(true)
              }}
            >
              <span className="mediaAdd-button">Add Video</span>
            </button>
          ) : (<></>)}
        </div>
      )}
      <MediaVideoModal
        requireAltText={requireAltText}
        showModal={showVideoModal}
        onCloseModal={() => setShowVideoModal(false)}
        onSaveModal={handleSaveMedia}
      />
      <MediaImageModal
        requireAltText={requireAltText}
        showModal={showImageModal}
        forceCrop={forceCrop}
        uploadImageObj={ogImageSrc}
        editImageObj={editImageObj}
        onCloseModal={() => setShowImageModal(false)}
        onSaveModal={handleSaveMedia}
        onCustomImageSave={customImageSave}
          />
      <PageLoadingSpinner show={showSaving} />
    </>
  );
}

MediaUploader.propTypes = {
  forceCrop: PropTypes.bool,
  imageOnly: PropTypes.bool,
  videoOnly: PropTypes.bool,
  requireAltText: PropTypes.bool,
  allowGallery: PropTypes.bool,
  mediaItems: PropTypes.array,
  saveMediaItems: PropTypes.func,
  customImageSave: PropTypes.func,
  allowSorting: PropTypes.bool,
  sortInTable: PropTypes.string,

}

MediaUploader.defaultProps = {
  forceCrop: false,
  imageOnly: false,
  videoOnly: false,
  requireAltText: false,
  allowGallery: true,
  mediaItems: [],
  saveMediaItems: null,
  customImageSave: null,
  allowSorting: false,
  sortInTable: null,
}