import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../styles/nav-menu.css';
import { listCategoriesProductNav } from '../../api/categoriesapi';
import { setProductTypes, setRoomTypes, setTypicalLayout } from '../../store/categories-reducer';
import CombinedMenu from './combined-menu';
import { defaultDistrict } from '../../store/default-district';
import { getTotalQuotes } from '../../api/districtquoteapi.js';
import { getLoginUserDetails } from '../../api/adminuserapi';
import { getSurveyList } from '../../store/survey/actions';
export default function NavMenu() {
    const dispatch = useDispatch();
    const [loginUser, setLoginUser] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [currentSubMenu, setCurrentSubMenu] = useState('main'); // 'main', 'product', 'productType', 'roomType', 'layoutType'
    const showBanner = useSelector((state) => state.layoutBanner.isVisible);
    const updatedDistrict = useSelector((state) => state.district.updated);
    const myProjectTotalQuantity = useSelector((state) => state.myProject.data.totalQuantity);
    const productTypes = useSelector((state) => state.categories.productType);
    const roomTypes = useSelector((state) => state.categories.roomType);
    const typicalLayoutTypes = useSelector((state) => state.categories.typicalLayoutType);
    const districtId = localStorage.getItem('districtId') ?? '';
    const { surveyList } = useSelector(state => state.surveyList);
    const [surveyfilter] = useState({ sort: 0, districtId: districtId });
    const [pendingRecordsCount, setPendingRecordsCount] = useState(0);
    const [district, setDistrict] = useState(defaultDistrict);
    const wishListItems = useSelector((state) => state.myWishlist.data.items);


    useEffect(() => {
        setDistrict({});
        console.log('detected district has changed'); 
        const districtJson = localStorage.getItem('district') ?? '';
        if (districtJson) {
            let districtData = JSON.parse(districtJson);
            setDistrict(prevDistrict => ({ ...prevDistrict, ...districtData }));
        }
    }, [updatedDistrict]);
    
    let editingTypicalLayout = localStorage.getItem('editTypicalLayout');

    useEffect(() => {
        
        listCategoriesProductNav(2, 0).then((response) => {
            //product types
            if (response.data.success === true) {
                var data = response.data.data;
                var list = [];
                data.forEach((a) => {
                    list.push({
                        imagePath: a.photo,
                        imageAlt: a.name,
                        name: a.name,
                        url: '/products/category/' + a.id,
                        id: a.id,
                    });
                });
                dispatch(setProductTypes(list));
            }

        });

        listCategoriesProductNav(1, 0).then((response) => {
            //room types
            if (response.data.success === true) {
                var data = response.data.data;
                var list = [];
                data.forEach((a) => {
                    list.push({
                        imagePath: a.photo,
                        imageAlt: a.name,
                        name: a.name,
                        url: '/products/category/' + a.id,
                        id: a.id,
                    });
                });
                dispatch(setRoomTypes(list));
            }
        });
        
        listCategoriesProductNav(4, 1).then((response) => {
            //typical layouts
            if (response.data.success === true) {
                var data = response.data.data;
                var list = [];
                data.forEach((a) => {
                    list.push({
                        imagePath: a.photo,
                        imageAlt: a.name,
                        name: a.name,
                        url: '/typical-layouts/category/' + a.id,
                        id: a.id,
                    });
                });
                dispatch(setTypicalLayout(list));
            }
        });
        
        getTotalQuotes(districtId, 0)
            .then((response) => {
                // Calculate pending records count
                if (response.data.success == true) {
                    setPendingRecordsCount(response.data.data);
                }

            });

        getLoginUserDetails().then((response) => {
            setLoginUser(response?.data);
        });

        dispatch(getSurveyList(surveyfilter));
    }, []);


    const isAdmin = loginUser?.data?.userRoles?.filter(x => x.siteRole.name === 'admin').length > 0;
    const isApprover = loginUser?.data?.userRoles?.filter(x => x.siteRole.name === 'approver').length > 0;
    const isManager = loginUser?.data?.userRoles?.filter(x => x.siteRole.name === 'manager').length > 0;

    const toggleMainMenu = () => {
        setIsMobileMenuOpen((prevState) => !prevState);
        setCurrentSubMenu('main');
    };


    const surveyActiveList = surveyList.filter(x => x.isActive === false);

    const RoomType = () => {
        return (
            <div id="mobile-nav-room-type">
                <div className="mobile-nav-center">
                    <div className="mobile-back-button-wrap">
                        <button
                            className="mobile-nav-page-back"
                            href="#"
                            onClick={() => openMenu('submain')}
                        >
                            Products
                        </button>
                        <a
                            className="mobile-nav-page-close"
                            href="#"
                            onClick={toggleMainMenu}
                        >
                            <span className="sr-only">Close Menu</span>
                            <span className="material-symbols-rounded">close</span>
                        </a>
                        <span className="mobile-nav-link-title no-link">
                            Browse by Room Type
                        </span>
                        {roomTypes &&
                            roomTypes.map((type) => {
                                return (
                                    <Link
                                        className="mobile-nav-link-small"
                                        key={type.id}
                                        to={type.url}
                                        onClick={toggleMainMenu}
                                    >
                                        {type.name}
                                    </Link>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    };
    const ProductType = () => {
        return (
            <div id="mobile-nav-product-type">
                <div className="mobile-nav-center">
                    <div className="mobile-back-button-wrap">
                        <button
                            className="mobile-nav-page-back"
                            href="#"
                            onClick={() => setCurrentSubMenu('submain')}
                        >
                            Products
                        </button>
                        <button className="mobile-nav-page-close" onClick={toggleMainMenu}>
                            <span className="sr-only">Close Menu</span>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <span className="mobile-nav-link-title no-link">
                            Browse by Product Type
                        </span>
                        {productTypes &&
                            productTypes.map((type) => {
                                return (
                                    <Link
                                        className="mobile-nav-link-small"
                                        key={type.id}
                                        to={type.url}
                                        onClick={toggleMainMenu}
                                    >
                                        {type.name}
                                    </Link>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    };
    const SubMainMenu = () => {
        return (
            <div>
                <div className="mobile-nav-center">
                    <div className="mobile-back-button-wrap">
                        <button
                            className="mobile-nav-page-back"
                            onClick={() => openMenu('main')}
                        >
                            All
                        </button>
                        <button className="mobile-nav-page-close" onClick={toggleMainMenu}>
                            <span className="sr-only">Close Menu</span>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <a
                            className="mobile-nav-link-title"
                            href="#"
                            id="room-type"
                            onClick={() => openMenu('room')}
                        >
                            Browse by Room Type
                        </a>
                    </div>
                </div>
            </div>
        );
    };
    const MobileMenu = () => {
        return (
            <div>
                <div>
                    <div className="mobile-nav-center">
                        <button
                            className="mobile-nav-page-close"
                            href="#"
                            onClick={toggleMainMenu}
                        >
                            <span className="sr-only">Close Menu</span>
                            <span className="material-symbols-rounded">close</span>
                        </button>
                        <Link
                            to="/products"
                            onClick={toggleMainMenu}
                            className="mobile-nav-link"
                            id="mobile-nav-products"
                        >
                            Products
                        </Link>
                        {typicalLayoutTypes && typicalLayoutTypes.length > 0 && !editingTypicalLayout &&
                            <Link
                                to="/typical-layouts"
                                className="mobile-nav-link"
                                id="mobile-nav-typical"
                                onClick={toggleMainMenu}
                            >
                                Typical Layouts
                            </Link>
                        }

                        {surveyActiveList && surveyActiveList.length > 0 && !editingTypicalLayout && (
                            surveyActiveList.length > 1 ? (
                                <Link to="/surveys" onClick={toggleMainMenu} id="mobile-nav-survey" className="mobile-nav-link">
                                    Surveys
                                </Link>
                            ) : (
                                    <a href={surveyActiveList[0].links} target="_blank" rel="noopener noreferrer" className="mobile-nav-link">
                                    Surveys
                                </a>
                            )
                        )}

                        {!district.isDemo && !editingTypicalLayout &&
                            <Link
                                to="/my-account"
                                className="mobile-nav-link"
                                id="mobile-nav-myaccount"
                                onClick={toggleMainMenu}
                            >
                                My Account
                            </Link>
                        }
                        {!editingTypicalLayout &&
                            <Link
                                to="/contact"
                                className="mobile-nav-link"
                                id="mobile-nav-myaccount"
                                onClick={toggleMainMenu}
                            >
                                Contact
                            </Link>
                        }
                        {!editingTypicalLayout && wishListItems.length > 0 &&
                            <Link
                                to="/my-project#wishlist"
                                className="mobile-nav-link"
                                id="mobile-nav-wishlist"
                                onClick={() => {
                                    toggleMainMenu();
                                    setTimeout(() => {
                                        document.getElementById('wishlist').scrollIntoView({ behavoir: 'smooth' });
                                    }, 1000);
                                }}
                            >
                                Wishlist
                            </Link>
                        }
                        {!editingTypicalLayout &&
                            <Link
                                to="/my-project"
                                className="mobile-nav-link"
                                id="mobile-nav-wishlist"
                                onClick={toggleMainMenu}
                            >
                                My Project
                            </Link>
                        }
                    </div>
                </div>
            </div>
        );
    };

    function openMenu(menuName) {
        setCurrentSubMenu(menuName);
    }

    // Scroll Position
    const [scrollPosition, setScrollPosition] = useState('docked');
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition < 1) {
            setScrollPosition('docked');
        } else {
            setScrollPosition('undocked');
        }
    };
    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const navbar = document.querySelector('.nav-wrap');
        const navSeat = document.querySelector('.nav-seat');

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    navbar.classList.add('docked');
                } else {
                    navbar.classList.remove('docked');
                }
            },
            {
                root: null,
                threshold: 1.0,
                rootMargin: '0px 0px 0px 0px',
            }
        );

        observer.observe(navSeat);

        // Cleanup observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <header className={(showBanner ? "banner-visible" : "")}>
            <div className="nav-seat">&nbsp;</div>
            <div className="nav-screen-dim">&nbsp;</div>
            <div className={'nav-wrap ' + scrollPosition}>
                <Link to="#" className="nav-overlay-close">
                    <span className="nav-overlay-close-icon">
                        <span className="material-symbols-rounded">close</span>
                    </span>
                </Link>
                <div className="nav-center">
                    <Link
                        to="#"
                        className="mobile-nav-menu-trigger"
                        title="menu"
                        onClick={toggleMainMenu}
                    >
                        <span>&nbsp;</span>
                    </Link>
                    <Link to="/home" className="nav-item-wrap nav-logo">
                        <div className="nav-logo-wrap">
                            {district?.logo && (
                                <img
                                    src={district.logo}
                                    className="nav-logo-img"
                                    alt="LSCR7 Logo"
                                />
                            )}
                        </div>
                        <span className="logo-title" style={{ color: district.color }}>
                            { district.isDemo == true ? 'Demo' : 'Approved Furniture' }
                        </span>
                    </Link>
                    <div className="nav-item-wrap nav-middle">
                        {/* <Link
							to="/discovery"
							className="nav-link"
							id="nav-survey"
							onClick={showSubmenu}
						>
							<span className="nav-link-cond">Product Discovery & Survey</span>
						</Link> */}
                        <Link
                            to="/products"
                            className="nav-link" id="nav-products">
                            Products
                        </Link>

                        {typicalLayoutTypes && typicalLayoutTypes.length > 0 && !editingTypicalLayout &&
                            <Link
                                to="/typical-layouts"
                                className="nav-link" id="nav-link">
                                Typical Layouts
                            </Link>
                        }

                        {surveyActiveList && surveyActiveList.length > 0 && !editingTypicalLayout && (
                            surveyActiveList.length > 1 ? (
                                <Link to="/surveys" className="nav-link">
                                    Surveys
                                </Link>
                            ) : (
                                    <a href={surveyActiveList[0].links} target="_blank" rel="noopener noreferrer" className="nav-link">
                                    Surveys
                                </a>
                            )
                        )}

                        {!district.isDemo && !editingTypicalLayout &&
                            <Link to="/my-account" className="nav-link">
                                My Account
                            </Link>
                        }
                        {!editingTypicalLayout &&
                            <Link to="/contact" className="nav-link">
                                Contact
                            </Link>
                        }
                    </div>
                    <div className="nav-item-wrap nav-right">
                        {/* <Link to="/broken" className="nav-link"><span className="icon-wrap"><span className="material-symbols-rounded">search</span></span></Link> */}
                        {((isAdmin || isManager || isApprover) && localStorage.typicallayout != 'true') &&
                            <Link to="/all-quotes" className="nav-link notification-bell">
                                <span className="icon-wrap">
                                    <span className="material-icons">notifications</span> {/* Bell icon */}
                                    {pendingRecordsCount > 0 && <span className="pending-count">{pendingRecordsCount}</span>}
                                </span>
                            </Link>
                        }
                        {localStorage.typicallayout != 'true' && !editingTypicalLayout && wishListItems.length > 0 &&
                            <Link to="/my-project#wishlist" onClick={() => {
                                try {
                                    document.getElementById('wishlist').scrollIntoView({ behavoir: 'smooth' });
                                } catch (ex) { console.log(ex); }
                                setTimeout(() => {
                                    document.getElementById('wishlist').scrollIntoView({ behavoir: 'smooth' });
                                }, 1000);
                            }} className="nav-link">
                                <span className="icon-wrap dark">
                                    <span className="material-symbols-rounded">favorite</span>
                                    <span className="favorite-numbers"></span>
                                </span>
                            </Link>
                        }
                        <Link
                            to="/my-project"
                            className="nav-link cart">
                            <span className="icon-wrap dark">
                                <span className="material-symbols-rounded">folder_open</span>
                                <span className="cart-numbers applied">
                                    {myProjectTotalQuantity}
                                </span>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>

            <CombinedMenu />
            {/*Mobile Overlay*/}
            <div className="">
                {isMobileMenuOpen && currentSubMenu === 'main' && <MobileMenu />}
                {currentSubMenu === 'submain' && <SubMainMenu />}
                {currentSubMenu === 'product' && <ProductType />}
                {currentSubMenu === 'room' && <RoomType />}
            </div>
        </header>
    );
}
