import React from 'react';

import UserIndexPage from './indexpage'
import UserEditPage from './editpage'

export function UserEdit() {
  return(<UserEditPage />)
}
export function UserIndex() {
  return (<UserIndexPage />);
}
