import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './productindex.css';
//components
import { AccordionItem } from '../../components/common/Accordion';
import { DropDown, Option } from '../../components/common/DropDown';
import Banner from '../../components/common/ShowBannerDetails';
import ProductImage from '../../components/common/ProductImage';
import { Scrollbars } from 'react-custom-scrollbars-2';
//api
import { filterProductsByDistrict } from '../../api/productapi';
import { getAllBySlugs, allCategories } from '../../api/categoriesapi';
//helpers
import { checkForDistrictId, isDemoDistrict, getDistrictPath } from '../../helpers/checkForDistrictId';
import { formatUSD } from '../../helpers/formatterHelper';
import { updateTitle, updateMetaDescription } from '../../helpers/titleHelper';

export default function Products() {
	updateTitle('Top Facility Management Products');
	updateMetaDescription("Browse Facility Standard's range of facility management products designed to streamline operations and improve efficiency. Find the perfect solution for your needs today!");
	//const { slugs, sort } = useParams();
	const { slugs: initialSlugs, sort } = useParams();
	const navigate = useNavigate();
	const [slugs, setSlugs] = useState(initialSlugs || null);
	const [filter, setFilter] = useState({
		start: 1,
		length: 20,
		orderby: sort || 2,
		categoryIds: [],
	});
	const [products, setProducts] = useState({ total: 0, results: [] });
	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const [scroll, setScroll] = useState({
		hasScroll: false,
		foundBottom: false,
		totalProducts: 0,
	});
	const productsRef = React.useRef(products);
	const scrollRef = React.useRef(scroll);
	const [showMore, setShowMore] = useState({ 0: false, 1: false, 2: false });
	const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
	const [isMobileSortByOpen, setIsMobileSortByOpen] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isRefreshing, setIsRefreshing] = useState(true);

	useEffect(() => {
		checkForDistrictId(navigate); //redirect to admin/districts if user is not logged into district
		if (slugs != null && slugs != '') { 
			getAllBySlugs(slugs, 0).then(response => { //0 = category group type for products
				if (response.data.success == true) {
					setFilter({ ...filter, categoryIds: response.data.data });
				}
			});
		}
	}, [slugs]);

	useEffect(() => {
		if (slugs && filter.categoryIds.length == 0) { return; }
		let editTypicalLayout = localStorage.getItem('typicallayout');
		let typicalProductDetails = JSON.parse(localStorage.getItem('editTypicalLayout')) || [];
		let districtID = editTypicalLayout && typicalProductDetails && typicalProductDetails.districtId ? typicalProductDetails.districtId : localStorage.getItem('districtId');
		setIsRefreshing(true);
		filterProductsByDistrict(
			districtID,
			filter.start,
			filter.length,
			filter.orderby,
			filter.categoryIds
		)
			.then((response) => {
				var p = response.data.data;
				var prods = {
					total: p.total,
					results:
						products.results.length > 0
							? [...products.results, ...p.results]
							: p.results,
				};
				setIsFetching(false);
				setProducts({ ...prods });
				productsRef.total = prods.total;
				productsRef.results = prods.results;
				allCategories(filter.categoryIds,0)
					.then((cats) => {
						setCategories(cats.data.data);
					})
					.catch((err) => {
						setError('Error fetching categories: ' + err);
					})
					.finally(() => {
						setIsLoading(false);
					});
				if (filter.start === 1) {
					document.body.scrollTo({ top: 0, behavior: 'smooth' });
				}
				setIsRefreshing(false);
			})
			.catch((err) => {
				setError('Error fetching district products: ' + err);
			});
	}, [filter]);

	useEffect(() => {
		let plen = products.results.filter((a) => a.details).length;
		if (plen > scroll.totalProducts) {
			setScroll({ ...scroll, foundBottom: false, totalProducts: plen });
			scrollRef.foundBottom = false;
			scrollRef.totalProducts = plen;
		}
	}, [products]);

	const fetchMoreProducts = () => {
		if (filter.start < products.total && !isFetching) {
			setIsFetching(true);
			setFilter((prev) => ({ ...prev, start: prev.start + prev.length }));
		}
	};

	const updateFilteredCategories = (e, id) => {
		var isChecked = e.target.checked;
		var c = filter.categoryIds;
		if (isChecked) {
			c.push(id);
		} else {
			c = c.filter((a) => a != id);
		}
		scrollRef.foundBottom = false;
		setProducts({ total: 0, results: [] });
		setScroll({ ...scroll, foundBottom: false });
		setFilter({ ...filter, start: 1, categoryIds: c });
		document.body.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const updateOrderBy = (x) => {
		setProducts({ total: 0, results: [] });
		setScroll({ ...scroll, foundBottom: false });
		setFilter({ ...filter, start: 1, orderby: x });
	};

	const updateUrl = () => {
		if (isLoading == true) { return; }
		var c = filter.categoryIds;
		var allcats = categories.flat(1);
		var sluglist = c.map(a => allcats.filter(b => b.id == a).map(b => b.slug));
		history.replaceState(
			null,
			'',
			'/products' + (c.length > 0 ? '/category/' + sluglist.join(',') + '/' : '/sort/') + filter.orderby + (isDemoDistrict() ? '?org=' + getDistrictPath() : '')
		);
	}

	useEffect(updateUrl, [filter, categories]);

	const clearFilter = () => {
		scrollRef.foundBottom = false;
		setProducts({ total: 0, results: [] });
		setScroll({ ...scroll, foundBottom: false });
		setSlugs(null);
		//setFilter({ ...filter, categoryIds: [] }); 
		setFilter((prevFilter) => ({
			...prevFilter,
			start: 1,
			length: 20,
			orderby: 2,
			categoryIds: [],
		}));
		document.body.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const CategoryAccordions = () => {
		return [
			{ id: 0, name: 'Room Type' },
			{ id: 1, name: 'Categories' },
			{ id: 2, name: 'Grade Level' }
		].map((x) => (
			<AccordionItem key={x.id} title={x.name} opened={true}>
				<div className="product-checkboxes">
					{categories && categories.length > x.id ? (
						(showMore[x.id]
							? categories[x.id]
							: categories[x.id].slice(0, 4)
						).map((c) => (
							<div
								key={'category_' + c.id}
								className={`product-checkbox${
									c.productCount === 0 ? ' disabled' : ''
								}`}
							>
								<label
									className="container-checkbox"
									htmlFor={'category_' + c.id}
								>
									<span className="checkbox-title">{c.name}</span>
									<input
										type="checkbox"
										id={'category_' + c.id}
										checked={
											filter.categoryIds.filter((a) => a == c.id).length > 0
										}
										onChange={(e) => {
											updateFilteredCategories(e, c.id);
										}}
										disabled={c.productCount === 0}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						))
					) : (
						<></>
					)}
				</div>
				{categories &&
				categories.length > x.id &&
				categories[x.id].length > 4 &&
				!showMore[x.id] ? (
					<button
						className="show-all-title"
						onClick={() =>
							setShowMore((prevShowMore) => ({ ...prevShowMore, [x.id]: true }))
						}
					>
						Show All
					</button>
				) : null}
			</AccordionItem>
		));
	};

	const SelectedCategories = () => {
		const categoryLookup = React.useMemo(() => {
			const lookup = {};
			categories.forEach((catGroup) => {
				catGroup.forEach((cat) => {
					lookup[cat.id] = cat.name;
				});
			});
			return lookup;
		}, [categories]);

		const handleRemoveFilter = useCallback((id) => {
			setFilter((prevFilter) => {
				const updatedCategories = prevFilter.categoryIds.filter(
					(categoryId) => categoryId !== id
				);
				document.body.scrollTo({ top: 0, behavior: 'smooth' });

				if (updatedCategories.length === 0) {
					setSlugs(null);
				}

				return { ...prevFilter, categoryIds: updatedCategories, start: 1 };
			});
			scrollRef.foundBottom = false;
			setProducts({ total: 0, results: [] });
			setScroll({ ...scroll, foundBottom: false });
		}, []);
		return filter.categoryIds.length > 0 ? (
			filter.categoryIds.map((c) => {
				const categoryName = categoryLookup[c];
				if (categoryName) {
					return (
						<div
							className="product-filter-active"
							key={'filter_' + c}
							onClick={() => handleRemoveFilter(c)}
						>
							{categoryName}
						</div>
					);
				}
				return null;
			})
		) : (
			<></>
		);
	};

	const goToProduct = (p) => {		
			navigate(`/products/${p.slug}`, { state: { from: 'results' } });		
	};

	const maxColors = 6;

	const ProductList = () => {
		//const [imageError, setImageError] = useState(false);
		console.log(products.results)
		return products.results.length > 0 ? (
			products.results
				.filter((a) => a.details && a.details.name)
				.map((p) => {
					const colors = p.options.filter((a) => a.type == 2).map(a => a.items).flat();

					return (
						<div
							key={'product_' + p.id}
							className="product-gallery-item"
							onClick={() => goToProduct(p)}
						>
							<span className={'product-item-image favorite'}>
								<ProductImage
									photo={p.photo}
									photoAlt={p.photoAlt ?? p.details.name}
									styles="cover-thumb"
								/>
								{/*<span className="errorImage">Image Coming Soon</span>*/}
							</span>
							<span className="product-item-content">
								<span className="product-title-amount-wrap">
									<span className="product-item-title">{p.details.name}</span>
									<span className="product-item-amount">
										{formatUSD(
											p.details.price -
											(p.details.discount > 0
												? p.details.price * (p.details.discount / 100)
												: 0)
										)}
									</span>
								</span>
								<span className="product-color-wrap">
									{p.options &&
										p.options.length > 0 &&
										colors.length > 0 &&
										colors.slice(0, maxColors)
											.filter((a) => a.color).sort((a, b) => a.color.order - b.color.order)
											.map((a) => (
												<span
													key={'color_' + p.id + '_' + a.id}
													className="color-dots"
													title={a.color.name}
													style={{ background: a.color.hex }}
												>
													{a.color.colorImage ? (
														<img
															src={a.color.colorImage.previewsrc}
															style={{ height: '100%' }}
															alt={a.color.name}
														/>
													) : (
														<></>
													)}
												</span>
											))}

									{colors.length > maxColors && (
										<span className="color-dots color-plus-icon">
											<span className="material-symbols-rounded">
												add
											</span>
										</span>
									)}

								</span>
							</span>
						</div>
					);
				})
		) : isRefreshing ? <div className="no-results">Loading Results...</div> : <div className="no-results">No Results Found</div>;
	};
	const toggleMobileFilter = () => {
		setIsMobileFilterOpen((prevState) => !prevState);
	};
	const toggleMobileSortBy = () => {
		setIsMobileSortByOpen((prevState) => !prevState);
	};
	const MobileSortBy = () => {
		const options = [
			{ title: 'Price: Low - High', value: 0 },
			{ title: 'Price: High - Low', value: 1 },
			{ title: 'Brand: (A - Z)', value: 2 },
		];
		const [selectedOrder, setSelectedOrder] = useState(filter.orderby);
		const handleRadioChange = (value) => {
			setSelectedOrder(value);
		};

		const handleApply = () => {
			if (selectedOrder !== null) {
				updateOrderBy(selectedOrder);
			}
		};
		return (
			<div className="filters-overlay sorting-overlay">
				<div className="filters-overlay-inner">
				<button
					className="mobile-product-nav-close"
					onClick={toggleMobileSortBy}
				>
					<span className="material-symbols-rounded">close</span>
				</button>
				<div className="product-filter-sort-wrap">
					<p className="product-title">Sort By</p>
				</div>
				<div className="product-item product-item-sort-wrapper">
					<div className="product-item-sort-wrap">
						<div className="product-item product-item-sort">
							{/* Radio */}
							<div className="product-radios">
								{options.map((option, index) => (
									<div className="product-radio" key={'radios_' + index}>
										<label
											className="container-radio"
											htmlFor={`option-${option.value}`}
										>
											<span className="radio-title">{option.title}</span>
											<input
												type="radio"
												id={`option-${option.value}`}
												name="product-sorting"
												onChange={() => handleRadioChange(option.value)}
												checked={selectedOrder === option.value}
											/>
											<span className="radio"></span>
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
					</div>
				
				<div className="product-item-filter cta-wrapper">
					<div className="product-item-filter-inner-wrap">
						<button
							className="default-cta power default-full"
							onClick={handleApply}
						>
							Apply
						</button>
					</div>
				</div>
				</div>
			</div>
		);
	};

	const MobileFilter = () => {
		return (
			<div className="filters-overlay">
				<div className="filters-overlay-inner">
					{' '}
					{/*Removed the following -- product-inner-item product-nav*/}
					{/*@*sticky overlay bar*@*/}
					<div className="product-inner-overlay-bar">
						<button
							className="mobile-product-nav-close"
							onClick={toggleMobileFilter}
						>
							<span className="material-symbols-rounded">close</span>
						</button>
						<div className="product-item filter-results-wrap">
							<p className="product-title">Filter</p>
							<div className="product-filter-controls-wrap">
								<p className="product-results">{products.total + ' Result' + (products.total != 1 ? 's' : '')}</p>
								{filter.categoryIds.length > 0 ? (
									<>
										<a
											href="javascript:"
											onClick={clearFilter}
											className="product-clear"
										>
											Clear Filters
										</a>
									</>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
					{/*@*scrollable area on 900 down*@*/}
					<div className="product-item product-item-filter-wrap scrollbar-outer product-filter">
						<Scrollbars
							autoHeight
							autoHeightMin={400}
							autoHeightMax={800}
							renderTrackHorizontal={(props) => (
								<div {...props} className="track-horizontal" />
							)}
							renderTrackVertical={(props) => (
								<div {...props} className="track-vertical" />
							)}
							renderThumbHorizontal={(props) => (
								<div {...props} className="thumb-horizontal" />
							)}
							renderThumbVertical={(props) => (
								<div {...props} className="thumb-vertical" />
							)}
							renderView={(props) => <div {...props} className="view" />}
						>
							<div className="product-content-center">
								<CategoryAccordions></CategoryAccordions>
							
							</div>
						</Scrollbars>
					</div>
					<div className="product-item-filter cta-wrapper">
						<div className="product-item-filter-inner-wrap">
							<a
								href="javascript:"
								onClick={clearFilter}
								className="default-cta"
							>
								Clear Filters
							</a>
							<a href="javascript:" onClick={toggleMobileFilter} className="default-cta power">
								{products.total + ' Result' + (products.total != 1 ? 's' : '')}
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	};
	if (error) {
		return <h1>{error}</h1>;
	}
	if (isLoading) {
		return <div className="loading">Loading...</div>;
	}
	return (
		<div id="content">
			<Banner/>
			{/*Filter Overlay*/}
			{isMobileFilterOpen && <MobileFilter />}
			{isMobileSortByOpen && <MobileSortBy />}
			<div className="default-outer-wrapper">
				<div className="default-center">
					<div className="page-header-wrap">
						<h1 className="product-main-title">Products</h1>
						<div className="product-mobile-results-wrap">
							<div className="product-controls-wrap">
								<a
									href="javascript:"
									className="mobile-product-filters asf"
									onClick={toggleMobileFilter}
								>
									Filter{' '}
									{filter.categoryIds.length > 0
										? '(' + filter.categoryIds.length + ')'
										: ''}
								</a>
								<a
									className="mobile-product-sort-wrap"
									onClick={toggleMobileSortBy}
								>
									Sort By
								</a>
								<div className="sort-by-wrap">
									<label className="sort-by-label" htmlFor="sortByDropDown">
										Sort By
									</label>
									<DropDown onChange={updateOrderBy} defaultValue={filter.orderby}>
										<Option title="Price: Low - High" value={0}></Option>
										<Option title="Price: High - Low" value={1}></Option>
										<Option title="Brand: (A - Z)" value={2}></Option>
									</DropDown>
								</div>
							</div>
							<a
								href="javascript:"
								className="product-results mobile-product-results"
							>
								{isLoading ? '' : products.total + ' Result' + (products.total != 1 ? 's' : '')}
							</a>
						</div>
					</div>
					<div className="mobile-product-overlay-dim"></div>
					<div className="product-wrap">
						<div className="product-inner-item product-nav">
							<div className="product-inner-overlay-bar">
								<a href="javascript:" className="mobile-product-nav-close">
									<span className="material-symbols-rounded">close</span>
								</a>
								<div className="product-item filter-results-wrap">
									<p className="product-title">Filter</p>
									<div className="product-filter-controls-wrap">
										<p className="product-results">{products.total + ' Result' + (products.total != 1 ? 's' : '')}</p>
										{filter.categoryIds.length > 0 ? (
											<a
												href="javascript:"
												onClick={clearFilter}
												className="product-clear"
											>
												Clear Filters
											</a>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
							{filter.categoryIds.length > 0 ? (
								<div className="product-filter-selected">
									<div className="filter-list">
										<div className="filter-list-innner">
											<SelectedCategories></SelectedCategories>
										</div>
									</div>
								</div>
							) : (
								<></>
							)}

							<div className="product-item product-item-filter-wrap scrollbar-outer">
								<div className="product-content-center">
									<CategoryAccordions></CategoryAccordions>
									<div className="product-item-filter cta-wrapper">
										<div className="product-item-filter-inner-wrap">
											{filter.categoryIds.length > 0 ? (
												<a
													href="javascript:"
													onClick={clearFilter}
													className="product-clear"
												>
													Clear Filters
												</a>
											) : (
												<></>
											)}
											<a href="javascript:" onClick={toggleMobileFilter} className="default-cta power">
												{products.total + ' Result' + (products.total != 1 ? 's' : '')}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="product-inner-item">
							<div className="product-gallery-inner">
								<ProductList />
								<div className="product-detail-loader-spinner">
									{isFetching ? <div className="spinner"></div> : ''}
								</div>
							</div>
							{/*Intersection Target for fetching NewProduct */}
							<ObserverTarget onIntersect={fetchMoreProducts} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function ObserverTarget({ onIntersect }) {
	const ref = React.useRef();

	React.useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const firstEntry = entries[0];
				if (firstEntry.isIntersecting) {
					onIntersect();
				}
			},
			{ threshold: 1.0, rootMargin: '0px' }
		);

		const currentRef = ref.current;
		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, [onIntersect]);

	return <div ref={ref}></div>;
}

ObserverTarget.propTypes = {
	onIntersect: PropTypes.func.isRequired,
};
