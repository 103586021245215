import React, { useState, useEffect } from 'react';
import { formatUSD, calculateDiscountedPrice } from '../../helpers/formatterHelper';
import PropTypes from 'prop-types';
import $ from "jquery";

export const AddSelect = ({ data, handleOptionSelection, requiredOption }) => {
    const [selectedSingleOption, setSelectedSingleOption] = useState({});
    const [selectedMultiOption, setSelectedMultiOption] = useState([]);
    const sortedItems = data?.items?.sort((a, b) => a.order - b.order) || [];
    const queryParams = new URLSearchParams(location.search);

    const handleSelection = (item, type) => {
        if ($(".product-detail-add-on-wrap").hasClass('PeoductSelected')) {
            $(".product-detail-add-on-wrap").removeClass('PeoductSelected');
        }
        if ($(`#${item.id}`).hasClass('PeoductSelected')) {
            $(`#${item.id}`).removeClass('PeoductSelected');
        } else {
            $(`#${item.id}`).addClass('PeoductSelected');
        }



        selectedSingleOption &&
            selectedSingleOption.id === item.id ? (
            $(`#${item.id}`).removeClass('PeoductSelected')
        ) : (
            $(`#${item.id}`).addClass('PeoductSelected')
        )


        if (type === 4) {
            if (selectedSingleOption && item.id === selectedSingleOption.id) {
                setSelectedSingleOption(null);
            } else {
                setSelectedSingleOption(item);
            }
        } else {
            if (Array.isArray(selectedMultiOption)) {
                if (selectedMultiOption.some((opt) => opt.id === item.id)) {
                    setSelectedMultiOption((prevOptions) =>
                        prevOptions.filter((opt) => opt.id !== item.id)
                    );
                } else {
                    setSelectedMultiOption((prevOptions) => [...prevOptions, item]);
                }
            }
        }
        handleOptionSelection(item, type);
    };

    const [isRequired, setIsRequired] = useState(false);
    useEffect(() => {
        const item = requiredOption && requiredOption.find(obj => obj.id === data.id);
        if (item && item !== undefined) {
            setIsRequired(true);
        } else {
            setIsRequired(false);
        }
    }, [requiredOption]);
    useEffect(() => {
        if (sortedItems.length == 1 && !queryParams.get('cartid')) {
            if (data.type === 4) {
                setSelectedSingleOption(sortedItems[0]);
            }
            else {
                if (sortedItems.length == 1) {
                    setSelectedMultiOption(sortedItems);
                }
                else {
                    setSelectedMultiOption(sortedItems[0]);
                }
            }
        }
    }, [sortedItems])

    return (
        <div className="product-dimensions-wrap">
            <div className="prod-dim-inner">
                <p className="product-dimension-title">
                    {data.title + (data.isRequired ? ' *' : '')}

                    {isRequired ? <div className="product-required-label-wrap">
                        <p className="product-required-label">Selection Required</p>
                    </div> : null}
                </p>

                {sortedItems &&
                    sortedItems.map((item) => {
                        return (
                            <div
                                className="product-detail-add-on-wrap selected"
                                id={item.id}
                                key={item.id}
                                data-action="dimensions"
                                onClick={() => handleSelection(item, data.type)}
                            >  {item.mediaItem && (
                                <span className="product-add-on-image-wrap">
                                    <img
                                        className=""
                                        src={item.mediaItem.previewsrc}
                                        alt={item.description}
                                    />
                                </span>
                            )}
                                <span className={`${item.mediaItem ? "product-add-on-content-wrap" : "product-add-on-content-wrap-withoutimage"}`}>
                                    <span className="product-add-on-content">
                                        <span className="product-dimension-title">
                                            {item.title}
                                        </span>
                                        <span className="product-dimension-desc">
                                            {item.description}
                                        </span>
                                        {item.upCharge && item.upCharge > 0 ? (
                                            <span className="product-add-on-plus-amount">
                                                +{formatUSD(calculateDiscountedPrice(item.upCharge))}
                                            </span>
                                        ) : item.upCharge === 0 ? (
                                            <span className="product-add-on-plus-amount">
                                                $0.00
                                            </span>
                                        ) : null}

                                    </span>
                                    {data.type === 4 ? (
                                        selectedSingleOption &&
                                            selectedSingleOption.id === item.id ? (
                                            <span className="product-add-on-method">
                                                <span className="product-add-on-method-inner">
                                                    <span className="material-symbols-rounded dark">
                                                        done
                                                    </span>
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="product-add-on-method">
                                                <span className="product-add-on-method-inner">
                                                    <span className="material-symbols-rounded">add</span>
                                                </span>
                                            </span>
                                        )
                                    ) : (
                                        selectedMultiOption?.length > 0 &&
                                            selectedMultiOption.some(opt => opt.id === item.id) ? (
                                            <span className="product-add-on-method">
                                                <span className="product-add-on-method-inner">
                                                    <span className="material-symbols-rounded dark">
                                                        done
                                                    </span>
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="product-add-on-method">
                                                <span className="product-add-on-method-inner">
                                                    <span className="material-symbols-rounded">add</span>
                                                </span>
                                            </span>
                                        )
                                    )}
                                </span>
                            </div>
                        );
                    })}
            </div>

        </div>
    );
};

AddSelect.propTypes = {
    //data: PropTypes.objectOf(PropTypes.any),
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.any),
        sort: PropTypes.string,
        type: PropTypes.number,
        isRequired: PropTypes.bool,
        id: PropTypes.number
    }),

    handleOptionSelection: PropTypes.func,
    requiredOption: PropTypes.array
};
